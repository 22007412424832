// src/StripeModal.tsx

import React, { useEffect } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { toast } from 'react-toastify';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface StripeModalProps {
  clientSecret: string;
  isOpen: boolean;
  onClose: () => void;
  price: number;
  nameOfProduct: string;
  userEmail:string;

}

const CheckoutForm: React.FC<{
  clientSecret: string;
  onClose: () => void;
  price: number;
  nameOfProduct: string;
  userEmail:string;
}> = ({ clientSecret, onClose, price, nameOfProduct ,userEmail }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = React.useState<string | null | undefined>(null);
  const [isProcessing, setIsProcessing] = React.useState(false);

  useEffect(() => {
    console.log('CheckoutForm Mounted');
    console.log('Stripe Instance:', stripe);
    console.log('Elements Instance:', elements);
  }, [stripe, elements]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe or Elements not loaded');
      return;
    }

    setIsProcessing(true);
    console.log('Submitting payment...');

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        console.error('CardElement not found');
        setPaymentError('Card Element not found.');
        toast.error('Card Element not found.', { /* toast options */ });
        setIsProcessing(false);
        return;
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details:{
            email:userEmail
          }
        },
        
      });

      if (error) {
        console.error('Payment Error:', error.message);
        setPaymentError(error.message);
        toast.error(`Payment failed: ${error.message}`, { /* toast options */ });
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded:', paymentIntent);
        toast.success('Payment succeeded!', { /* toast options */ });
        onClose();
      } else {
        console.warn('Unexpected PaymentIntent status:', paymentIntent?.status);
        setPaymentError('Unexpected payment status.');
        toast.error('Unexpected payment status.', { /* toast options */ });
      }
    } catch (err) {
      console.error('Unexpected Error:', err);
      setPaymentError('An unexpected error occurred.');
      toast.error('An unexpected error occurred.', { /* toast options */ });
    } finally {
      setIsProcessing(false);
      console.log('Payment processing complete');
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#a0aec0',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
      <div className="px-4 py-8 rounded min-h-[100px]">
        <CardElement options={CARD_ELEMENT_OPTIONS} className="w-full h-full" />
        <div className='flex items-center pt-4  justify-between'>
          <div className='inline-flex items-center'>
            <h3 className='code text-ACS'>{price}$ </h3>
            <p className='text-TR'>/ month</p>
          </div>
          <div className='flex items-center text-ACS'>
            <p>{nameOfProduct}</p>
          </div>

        </div>
      </div>
      <div className='text-sm text-TR'>Credentials destination: {userEmail}</div>
      {paymentError && (
        <div className="text-red-500 text-sm">{paymentError}</div>
      )}
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className={`px-4 py-2 rounded ${isProcessing
          ? 'bg-gray-400 cursor-not-allowed'
          : 'bg-PM text-white hover:bg-ACS hover:text-SC '
          } transition duration-200`}
      >
        {isProcessing ? 'Processing...' : 'Pay'}
      </button>
    </form>
  );
};

export const StripeModal: React.FC<StripeModalProps> = ({
  clientSecret,
  isOpen,
  onClose,
  price,
  nameOfProduct,
  userEmail
}) => {
  if (!isOpen || !clientSecret) {
    console.log('StripeModal not open or no clientSecret');
    return null;
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe', // Must be one of 'stripe' | 'flat' | 'night' | undefined
    },
  };


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-SCS rounded-lg shadow-lg w-11/12 max-w-md p-6 relative border border-ACS">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl font-bold"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-xl font-semibold mb-4 text-TR">Pay with card</h2>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm userEmail={userEmail} nameOfProduct={nameOfProduct} price={price} clientSecret={clientSecret} onClose={onClose} />
        </Elements>
      </div>
    </div>
  );
};
